@font-face {
  font-family: "az2"; /* Nombre que usarás para referenciar la fuente */
  src: url("../experience//font//a2z.ttf") format("truetype"); /* Ruta al archivo TTF y formato */
  /* Puedes agregar más formatos si la fuente también está disponible en otros formatos como WOFF o WOFF2 */
}
#main-span{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 0;
  transition:  all .6s ease-in;
}

h1, h2, h3 {
  margin: 0;
  padding: 0;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  background-color: black;
}
body{
  margin: 0;
  padding: 0;
}
canvas {
  filter: brightness(0.9) saturate(1.5);
}

:root {
  --opacity: 0;
  --position: absolute;
  --anim-state: paused;
  --span-color: black;
  --img: invert();size: 0;
  --index: -1;
  --start-btn-scale: 1;
  --wigth: 80px;
  --heigh: 80px;

}

#renderer {
  transition: all 2.5s;
}
.dark {
  transition: all 1s;
  background-color: rgba(0, 0, 0, 0.4);
}
.fade-in {
  animation: fadeIn 2s ease-in forwards;
}
.fade-out {
  animation: fadeOut 2s ease-in forwards;
}
.visible{
  opacity: 1 !important;
  transition:all .6s ease-out;
}
.deactive {
  display: none !important;
}
.no-visible {
  transition: 1.4s;
  opacity: 0;
}

#renderer :has(.element:hover){
}

  

.d-none{
  display: none;
}

#munity,
#file,
#word,
#ntf,
#chat,
#soon{
  margin-bottom: 10vh;
}

.element {
  width: fit-content;
  height: auto;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: scale(1);
  transition: all 0.7s; 
  overflow: visible;
}

.element.desactive {
  visibility: hidden;
}
.element-b {
  color: rgb(147, 239, 255);
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 0.3rem;
  font-size: 35px;
  transition: all 0.7s;
  visibility: hidden;

  background: rgba(255, 255, 255, 0.059);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 2px solid rgb(147, 239, 255);

  -webkit-box-shadow: 0px 0px 25px 1px rgba(99, 232, 255, 0.3);
  -moz-box-shadow: 0px 0px 25px 1px rgba(99, 232, 255, 0.3);
  box-shadow: 0px 0px 25px 1px rgba(99, 232, 255, 0.3);
}
#overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
}
.active {
  opacity: 1 !important;
  visibility: visible !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(1) translate(-50%, -50%); /* Escala inicial */
  transition: transform 1s, opacity 1s; /* Agregar transición a transform y opacity */
}
.hover{
  transition:all 1.5s;
}
.no-focus{
  opacity: 0.4;
  top: 50%;
  left: 50%;
  position: absolute;

  transform:  translate(-50%, -50%); /* Escala inicial */
  transition:all 3.5s;
}
.not-active {
  opacity: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0)  translate(-50%, -50%); /* Escala inicial */
  transition: all 0.5s cubic-bezier(1, 0, 0.9, 0.4);
}

/*ICON */
.element-container{
  backdrop-filter: blur(10px);
  border-radius: 10px;
  height: 80px;
  width: 80px;
  transition: backdrop-filter 0.7s;
}
.no-blur{
  transition:all .5s;
  backdrop-filter: unset !important;
  background-color: transparent !important;
}

.element-b:hover {

  transition: all 0.7s;
}
.element-container:hover{
  transition: all 0.7s;
  -webkit-box-shadow: 0px 0px 8px 1px rgb(0, 247, 255);

}
.element:hover {
  color: rgb(255, 255, 255);
  transition: all 0.7s;
  top: 50%;
  left: 50%;
  transform:  translate(-50%, -50%); /* Escala inicial */
  --position: relative; /* Oculta y saca del flujo de diseño */
  --anim-state: running;
  --opacity: 1;
  --span-color: black;
  --size: 18px;
  --index: 2;
}
.element span {
  font-weight: 220;

  font-family: az2;
  text-align: center;
  color: white;
}
.element img {
  width: 80px;
  height: auto;
  padding: 45px;
  z-index: 3;
}
.dot-title{
  transition:all 1.5s;
  position: absolute;
  width: 220%;
  bottom: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -30px;
}
.element h2 {
  font-weight: 350;
  width: fit-content;
  font-family: az2;
  font-size: 1.2rem;
  text-align: center;
  color: white;
  padding: 0;
  margin: 0;
  margin: 0;
  transition: all 0.7s;
  pointer-events: none; /* Evita que el contenido sea interactivo durante la animación */
}
@keyframes round {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*Animacion de inicio
/* Definición de la animación */
/* Definición de la animación */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/*Drag Drop effects*/
.dg.ac {
  z-index: 100 !important ;
  display: none;
}

#cortain {
  background-color: transparent;
  position: fixed;
  width: 100vw;
  height: 100vh;
}
.cortain {
  background-color: black;
  position: fixed;
  z-index: 100;
  height: 0vh;
  width: 100%;

  transition: all 2s;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.1) 5%,
    rgb(0, 0, 0) 100%
  );
}
.cortain__top {
  top: 0;
  transform: rotate(180deg);
}
.cortain__bottom {
  bottom: 0;
}
.active-cortain {
  height: 20vh;
}
/*ROOT*/
.root {
  width:  100%;
  height: 100%;
  overflow: hidden;
  z-index: 99;
  position: fixed;
  display: grid;
  place-content: center;
  align-items: center;
}

/*BANNERS*/
.navbar {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 80px;
}
.navbar__logo {
  height: 50px;
  width:  100px;
  margin-top: 15px;
  background-image: url(../experience/img/logo.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.credit__logo.navbar__logo{
    position: absolute;
    margin: 0;
    top: 20px;
    animation: CreditMunity 1s ease-in forwards;
}

.CreditBackLogo{
  animation: CreditMunity2 1s ease-in forwards;
}

.refeImg{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-size: contain;
  background-position: center;
  z-index: 500;
  opacity: 0.25;
  pointer-events: none;
  background-repeat: no-repeat;
}

.navbar__back-btn {
  cursor: pointer;
  position: absolute;
  height: 40px;
  width: 120px;
  z-index: 100;
  top: 15px;
  left: 25px;
  background-image: url(../experience/img/back.svg);
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.5s;
}
.navbar__back-btn:hover {
  transition: all 0.5s;
  transform: scale(1.05);
}

.footer {
  position: fixed;
  height: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  color: #aaaaaa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}
.footer__action-btn {
  cursor: pointer;
  height: 35px;
  width: 120px;
  margin: 0 30px;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
}
.footer__text {
  text-align:  center;
  font-family: az2;
  font-weight: 150;
  text-transform: uppercase;
  font-size: 0.8rem;
}
.footer--hide-footer-btn {
  cursor: pointer;
  background-size: contain;
  background-image: url("../experience/img/credit.svg");
  background-position: left;
}
.footer--sound-btn {
  transition: 0.6s ease;
  cursor: pointer;
  background-image: url("../experience/img/onSound.svg");
  background-position: right;
  position: relative;
}

.footer--sound-btn_off{
  background-image: url("../experience/img/offSound.svg");
}

.footer--sound-btn_Bars{
  position: absolute;
  background-image: url("../experience/img/anim/soundBars.gif");
  width: 27px;
  height: 35px;
  background-size: contain;
  left: 50%;
  top: 11%;
}

.footer--sound-btn_off .footer--sound-btn_Bars{
  display: none;
}

.footer--sound-btn.min{
  height: 2px;
  transition: 0.6s ease;
  margin: 0 !important;
}

#sound-btn__span {
  cursor: pointer;
  position: absolute;
  padding-top: 2px;
  top: 50%;
  transform: translate(0, -50%);
  color: #15eded;
  text-align: center;
  right: 15px;
}

.footer #navigation-menu {
  width: 35%;
  max-width: 360px;
    display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  z-index: 0;
}
.navigation-menu__btn-container {
  display: flex;
  transition: 0.5s all;
}
.navigation-menu__btn-container.actived {
  margin-bottom: 25px;
}
.navigation-menu__btn-container:hover {
  margin-bottom: 20px;
  transition: 0.5s all;
}

.btn-container__icon {
  height: 50px;
  width: auto;
  position: relative;
  z-index: 10;
}

/*END BANNERS*/

/*LOAD SCREEN*/
.load-screen {
  width: 70vw;
  height: 70vh;
  display: grid;
  padding: 15vh 15vw;
  place-content: center;
  position: relative;
  background: black;
}
.load-screen__logo {
  position: absolute;
  top: 5vh;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 0 !important;
}
.load-screen__span {
  font-family: az2;
  font-weight: 150;
  padding: 8px 15px 6px;
  font-size: min(3.5vw, 0.7em);
  color: #aaa;
  text-align: center;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  transition: all 0.5s;
  background: rgba(128, 128, 128, 0.1);
  border-radius: 0.8rem;
  animation: focus 8s infinite both;
}
.load-screen__span:hover {
  background: rgba(128, 128, 128, 0.25) !important;
  transition: all 1.5s !important;
}
.load-screen__span *{
  cursor: pointer;
  animation: scales 5s infinite;
}

@keyframes CreditMunity {
  0%{  top: 20px; }
  70%{  top: 80px}
  100%{  top: 60px}
}

@keyframes CreditMunity2 {
  0%{  top: 60px; }
  90%{  top: 10px}
  100%{  top: 20px}
}

@keyframes focus {
  0%{  background: rgba(128, 128, 128, 0.1);}
  30%{  background: rgba(128, 128, 128, 0.12);}
  50%{  background: rgba(128, 128, 128, 0.08);}
  80%{  background: rgba(128, 128, 128, 0.12);}
  100%{  background: rgba(128, 128, 128, 0.1);}
}
@keyframes scales {
  0%{transform: scale(0.8);}
  50%{transform: scale(1);}
  100%{transform: scale(0.8);}

}
.action-container {
  width: 100%;
  height: 100%;
}
.action-container__start-btn {
  cursor: pointer;

  background: transparent;
  border: none;
  width:  220px;
  height: 220px;
  padding: 5px;
}
.start-btn__text {
  font-family: az2;
  font-weight: 100;
  color: white;
  font-size: 1.2em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.start-btn__icon {
  filter: opacity(0.5);
  width: 100%;
  height: 100%;
  background-image: url("../experience/img/inner_being_icon.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  transition: 0.5s all;
}
.start-btn__icon:hover {
  background-image: url("../experience/img/inner_being_icon-hover.png");
  transform: scale(0.9);
  transition: 0.7s all;
}
.start-btn__icon::after:hover {
  transform: scale(1);
}

.start-btn__icon::after {
  width: 120%;
  height: 120%;

  background-image: url("../experience/img/outter_begin_icon.png");
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: rotate360 infinite 160s linear;
}

@keyframes rotate360 {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/*END LOAD-SCREEN*/

/*FIRST SCREE*/
.first-screen {
  font-family: az2;
  font-weight: 300;
  display: flex;
  width:  95vw;
  height: 95vh;
  padding: 5vw 5vh;
}
.first-screen__intro-container {
  padding-left: 10vw;
  color: white;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
}
.intro-container__title  h1{
  text-align:  left;
  font-weight: 600;
  font-size: 2.8vw;
  line-height: 1.2;
}
.intro-container__sub-title h2{
  width: 140%;
  margin-bottom: 20px;
  text-align: left;
  color:  whitesmoke;
  font-size: 1.2vw;
}
.intro-container__control-panel {
  display: flex;
  width: 50%;
  justify-content: flex-start;
  align-items: center;
  /*padding: 30px 15px;*/
}

.intro-container__control-panel button:not(:hover) {
  opacity: 0.8;
  transition: all 0.8s;
}
.intro-container__control-panel button:hover {
  transform: scale(1.05);
  transition: all 0.8s;
}

.control-panel__intro-btn {
  cursor: pointer;
  margin-right: 15px;
  /*min-width: 27.5556%;*/
  height: 40px;
  border: none;
  border-radius: 1em;
  color: white;
  font-size: 1em;
  background-color: transparent;
  position: relative;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 10px;
  transition: all .4s;
}
.control-panel__intro-btn * {
  pointer-events: none;
}

.control-panel__intro-btn--features-btn {
  /*
  background: linear-gradient(
    90deg,
    rgba(0, 118, 255, 0.8) 0.1%,
    rgba(0, 0, 0, 0) 90%
  );
  */
  border:  solid rgb(0, 140, 255) 1px;
  background-color: #0076FFCC;
}

.control-panel__intro-btn--launch-app-btn {
  cursor: pointer;

  /*background-image: url("../experience/img/intro-btn__launch-app.png");
  */
  border:  solid rgba(0, 118, 255, 0.5) 2px;
}
.intro-btn__text {
  margin-right: 15px;
  margin-left: 25px;

  margin: 0 30px;
}
.intro-btn__icon {
  width: 30px;
  height: 30px;
  background-image: url("../experience/img/intro-btn__icon.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5px;
  display: none;
}
.control-panel__btn {
}

/*END FIRST*/

/*MAIN CONTENT*/

.swiper {
  width: 60vw;
  height: 620px;
}
.swiper-pagination {
  margin-top: 80px;
}
span.swiper-pagination-bullet {
  color: #fff;
  outline: solid 1px #15eded;
  text-align: center;
  padding: auto;
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  opacity: 1;
  box-shadow: 0px 0px 13px 0px rgba(21, 237, 237, 1);
  background: transparent;
}

.swiper-pagination-bullet-active {
  background: rgba(21, 237, 237, 1) !important;
}

.content-page.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: unset;
}

.swiper-slide.content-page:not(.swiper-slide-active) {
  opacity: 0 !important;
}

.content-page {
  display: flex;
  font-size: 1.8em;
  width: 112vw;
  height: 480px;
  max-width: 1920px;
  opacity: 0;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-shadow: 0 0 20 px rgba(255, 255, 255, 1);
  font-family: az2;
  color: white;
}
.content-page .control-panel__intro-btn--launch-app-btn{
  width: 250px;
  height: 50px;
  border:  solid #0358badf 5px;
  min-width: 180px;
  margin-top: 31px !important;
  /*display: none;*/
}
#ntf .content-page__title{
  width: 165vw;
}
.content-page__title {
  margin: 0;
  margin-bottom: 15px;
  font-size: 2.6em;
  font-weight: 450;
}
.content-page__text {
  line-height: 30px;
  font-weight: 200;
  margin-bottom: 0;
  max-width: 89vw;
  margin-top: 0px;
}
.content-page__span-msg {
  margin-top: 40px;
  font-weight: 150;
  color: #aaaaaa;
}
/*END CONTENT*/

/*CREDIT PAGE*/
.credit {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 100;
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  font-family: "az2";
  backdrop-filter: blur(90px);
  position: relative;
}
.credit__item {
  margin-top: 30px;
}
.credit__text {
  width: fit-content;
  line-height: 1.5rem;
}

.credit__first{
  line-height: 3rem;
  margin-top: -32px;;
  margin-bottom: -10px;
}

.credit__second{
  line-height: 2.8rem;
}

.credit__second_two{
  margin-top: 10px;
}

.ignoreMouse
{
  pointer-events: none;
}

.text__span {
  color: #15eded;
}
.credit__info {
  display: inline;
}
.credit__description {
  display: inline;
}

.credit__more-info {
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    display: flex;
    position: absolute;
    height: 100%;
    pointer-events: none;
}
.credit__more-info a{
  text-decoration: none;
  color: white !important;
  font-weight: 100;
}

.more-info__policy {
  position: absolute;
  left: 5vw;
  bottom: 8vh;
  pointer-events: all;
}
.more-info__terms {
  position: absolute;
  right: 5vw;
  bottom: 8vh;
  pointer-events: all;
}

.credit__back-btn {
  cursor: pointer;
  content: "back";
  background-image: url("../experience/img/return_credits.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 166px;
  height: 70px;
  position: absolute;
  bottom: 18vh;
}

.credit__footer {
  position: absolute;
  bottom: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100vw;
}
.footer__watermark {
  left: 15px;
  color: #15eded;
  margin-left: 20px;
  }
.footer__social {
  width: 100px;
  height: fit-content;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.social__title {
  width: fit-content;
  text-align: center;
}
.social__icon-link {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.icon-link__item {
  width: calc(100% / 4);
  height: 30px;
  background-size: contain;
  margin-top: 10px;
  background-repeat: no-repeat;
  background-position: center;
}
.icon-link__instagram {
  background-image: url("../experience/img/credits/icon_instagram.png");
}
.icon-link__discord {
  background-image: url("../experience/img/credits/icon_discord.png");
}
.icon-link__twitter {
  background-image: url("../experience/img/credits/icon_twiter.png");
}

/*END CREDIT PAGE*/

/*COMMONS*/
.letter {
  display: inline-block;
  line-height: 1em;
}


.preview-element{
  height: 140PX;
  width:  140px;
}
.padding-extra{
  height: 60px  !important;
  padding: 15px !important;
  margin-top: 30px !important;
  
}
.padding-extra .intro-btn__icon{
  height: 55px !important;
  width:  55px !important;
}
.padding-extra:hover{
  transition: all .4s;
  transform: scale(1.1);
}
